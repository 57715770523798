
import React from 'react';
import { connect } from 'react-redux';

class AboutUs extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
      }

    render() {
        
        return (
            <React.Fragment>
              <div className="row my-5">
              <div className="col-md-12 mb-4"><h1>About Us</h1></div>
                  <div className="col-md-12" style={{ textAlign: "justify", textJustify: "inter-word"}}>
                      <div>
                        <p>
                        Even though we are new to India, we are backed by a U.S. company, whose main business is to help big brands like Bacardi, Diageo, and Grey Goose understand their end-customer by reaching them directly via a Direct-to-Consumer (D2C) e-commerce portal. We have been running their complete end-to-end home delivery platform for years, including 1-hr delivery in certain markets.
                        </p>
                      </div>
                      <div>
                        <p>
                        We are backed by a seasoned team with experience in highly regulated industries. Our Founding team is comprised of serial entrepreneurs from top schools like Wharton, MIT, and IIT, with most over 15-20 years of experience in highly regulated industries like alcohol, stem cells and cannabis. 
                        </p>
                      </div>
                      <div>
                        <p>
                        Because of that deep compliance-driven background, we have built our proprietary face-match algorithms and KYC procedures to ensure compliance with all the state laws. We provide the safest place for the people of India (where permitted by law) to order alcohol online.
                        </p>
                      </div>
                  </div>
              </div>       
            </React.Fragment>
          );
     }
}

export default AboutUs;