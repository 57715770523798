import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import {
  Card, CardImg, CardBody
} from 'reactstrap';
import hotelImage from '../../assets/images/store-top-banner.jpg';
import {get as _get} from 'lodash';

import { PromotionEvent } from '../../Global/helper/react-ga';
import { cleanEntityData } from '../../Global/helper/commonUtil';

const styles = () => {

};
class RecentActivityComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            itemsInSlide: 1,
            responsive: { 0: { items: 1 } },
            galleryItems: this.galleryItems(),
            // stagePadding: { paddingLeft: 30, paddingRight: 30 }
        }
    }

    componentDidMount() {
    }

    galleryItems() {
        return _get(this.props,'banners',[])
          .map((item, i) => {
            return (
              <Card  style={{cursor: 'pointer' }} onClick={() => this.handleSotreOnClick(item)} key={i}>
                <CardImg top width="100%"  src={item.imageurl} alt="Hotel Image" />
                      {/* <CardBody style={{ color: 'black'}}>
                          <div >
                              BINNY'S
                          </div>
                          <div >
                             LOGAN SQ. - 2.5 MI
                          </div>                      
                      </CardBody> */}
                  </Card>
            );
        })
    };
    handleSotreOnClick = (item) => {
        
        const payload = cleanEntityData({
            productId: _get(item, 'id'),
            name: _get(item, 'name'),
            creative: _get(item, 'creative'),
            position: _get(item, 'position')

        });
        // console.log(payload);
        PromotionEvent(payload);
        
    };

    render() {
        const { classes } = this.props;
        const { currentIndex, galleryItems, responsive } = this.state
        return (
            <React.Fragment>
                <div>
                    <AliceCarousel
                    items={galleryItems}
                    slideToIndex={currentIndex}
                    responsive={responsive}
                    dotsDisabled={true}
                    // mouseTrackingEnabled={true}
                    buttonsDisabled={true}
                    onInitialized={this.handleOnInitialChange}
                    onSlideChanged={this.handleOnSlideChange}
                    keysControlDisabled={true}
                    // stagePadding={stagePadding}
                    showSlideInfo={false}
                    infinite={false}
                    // playButtonEnabled={true}
                    // onResized={this.handleOnSlideChange}
                    />
                    {/* <button onClick={this.slidePrevPage}>Prev Page</button>
                    <button onClick={this.slideNextPage}>Next Page</button> */}
                    
                </div>

            </React.Fragment>
            
        )
    };
};

function mapStateToProps(state) {
    
    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(RecentActivityComponent));