/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:f176662f-8de0-41ff-a074-f518f0c1f14e",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_I4KWkmKRW",
    "aws_user_pools_web_client_id": "dsm50fd362950ii4v37i76hqi",
    "oauth": {},
    "aws_user_files_s3_bucket": "zoodrinkindiaamplify07145f2808b64b1a88eb233e3e5121112-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://tgrqyg4kl5edfbng677mivjg34.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-oypxxflxybdaxii5pgsjyfr6km",
    "aws_cloud_logic_custom": [
        {
            "name": "verifyFaces",
            "endpoint": "https://uh64za6qye.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ]
};


export default awsmobile;
