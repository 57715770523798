import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { map as _map, findIndex as _findIndex, find as _find, get as _get } from 'lodash';
import { Container, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import SearchOutlined from '@material-ui/icons/SearchOutlined'
import genericPostData from "../../Redux/Actions/genericPostData";
import { debounce } from 'lodash';
import { commonActionCreater } from '../../Redux/Actions/commonAction';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import LoopIcon from '@material-ui/icons/Loop';

const styles = theme => ({

});

class SearchProductsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            selectedTab: "",
            isLoading: true,
            searchValue: ''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleSearchProducts = (e) => {
        this.setState({searchValue:e.target.value})
        let searchRequest = _get(e, "target.value", "");
        this.props.dispatch(commonActionCreater({},"SEARCH_PRODUCTS_LIST_SUCCESS"));

        this.debouncedSearch(searchRequest);

    }

    searchProductsFetchSuccess = (searchTerm) => {
        this.props.dispatch(commonActionCreater(searchTerm,"GLOBAL_PRODUCT_SEARCH"));
    }

    searchProductsFetchError = () => {

    }
    debouncedSearch = debounce((searchTerm) => {
        searchTerm = searchTerm.trim();
        if(searchTerm){
            const zipcode = localStorage.getItem("zipcode");
            // const location = localStorage.getItem("location");
            const locTime = localStorage.getItem("dineinTime");
            const loc_id = localStorage.getItem("vendor_location_id");
            const couriertype = localStorage.getItem("couriertype");
            let page = 1;
            this.state.limit = 20;
    
            genericPostData({
                dispatch: this.props.dispatch,
                reqObj: { q: searchTerm },
                url: `/connect/index/search?q=${searchTerm}&store_id=1&zipcode=${zipcode}&loc_id=${loc_id}&courier_type=${couriertype}&page=1&limit=${this.state.limit}`,
                constants: {
                    init: "SEARCH_PRODUCTS_LIST_INIT",
                    success: "SEARCH_PRODUCTS_LIST_SUCCESS",
                    error: "SEARCH_PRODUCTS_LIST_ERROR"
                },
                identifier: "SEARCH_PRODUCTS_LIST",
                successCb: ()=>this.searchProductsFetchSuccess(searchTerm),
                errorCb: this.searchProductsFetchError,
                dontShowMessage: true
            })
        }
       
    }, 1000);

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <Container fluid={true} className="d-flex justify-content-center" >
                    <div className="pt-5 search-panel">
                        <Input value={this.state.searchValue} onChange={(e) => this.handleSearchProducts(e)} className="searchItembar" placeholder="SEARCH PRODUCTS" />
                        {this.state.searchValue.length > 0 && this.props.isLoading && this.props.location.pathname!='/store/search' ?
                            <span style={{
                                "cursor": "pointer",
                                "fontSize": "1.4rem",
                                "fontWeight": "700",
                                "minHeight": "40px",
                                "color": "red"
                            }}><LoopIcon style={{"fontSize":"1.4rem"}}></LoopIcon>Fetching Results Please Hold On...</span>
                            : this.state.searchValue.length>0 && _get(this.props,"productListingData.pagination.total_records") > 0 && <span style={{
                                "cursor": "pointer",
                                "fontSize": "1.4rem",
                                "fontWeight": "700",
                                "minHeight": "40px",
                                "color": "green"
                            }} onClick={() => this.props.history.push("/store/search")}>Total {_get(this.props,"productListingData.pagination.total_records")} Result.
                            {this.props.location.pathname!='/store/search'?'Click Here to See Result':'Matching Results are Showning Below'}</span>    
                        }
                    </div>
                </Container>
            </React.Fragment>

        );
    }
}

function mapStateToProps(state) {
    let isLoading = _get(state, 'searchProductsData.isFetching')
    let productListingData = _get(state, 'searchProductsData.lookUpData', {});
    return { productListingData, isLoading };

}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(SearchProductsContainer)));