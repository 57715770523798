
import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import { connect } from 'react-redux';


class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>          
            
            <Container className="peripheralContent">
            <div className="row my-5">
          <div className="col-md-12">    
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "row", justifyContent: "center" }} className="col-md-12 mb-4"><h1>CONTACT US</h1></div>
                    <div className="col-md-12" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                        <div style={{ marginTop: "30px", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <p>
                                DRINKS BUCKET
                        </p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <p>
                            B-7, Saraswati Colony, Tonk Phatak
                        </p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <p>
                            Jaipur, Rajasthan, 302015
                        </p>
                        </div>
                    </div>
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "row", justifyContent: "center" }} className="col-md-12 mb-4"><h2>FOR GENERAL INQUIRIES AND CUSTOMER CARE ASSISTANCE</h2></div>
                    <div className="col-md-12">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" , wordBreak:"break-word"}}>
                            <p>
                                PHONE: 8441835825 . EMAIL: <a href="mailto:contact@drinksbucket.com">contact@drinksbucket.com</a>
                        </p>
                        </div>
                    </div>
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "row", justifyContent: "center", wordBreak:"break-word" }} className="col-md-12 mb-4">
                        <h2>CUSTOMER CARE AGENTS ARE AVAILABLE FROM 9AM-7PM, MONDAY-FRIDAY</h2></div>
                </div>
                </div>
                </Container>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return { }
}
export default connect(mapStateToProps)(ContactUs);