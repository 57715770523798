import React from 'react';
import { Route } from 'react-router-dom';

const RouteWithoutLayout = ({ Component, ...rest }) => {

  return (
    <Route {...rest} render={props =>
      
        <Component {...props} />
     
    }
    />
  )
}

export default RouteWithoutLayout;