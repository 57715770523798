import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import {
    Card, CardImg, CardBody, Button
} from 'reactstrap';
import wineImage from '../../assets/images/White-Chalk-Hill-Sonoma-Coast-Chardonnay-2016.jpg';
import hotelImage from '../../assets/images/hotel_bar_demo.jpg';
import everestVodka from '../../assets/images/Titos.jpg'
import { map as _map, get as _get, find as _find, sortBy, isEmpty as _isEmpty, filter as _filter } from 'lodash';
import { commonActionCreater } from "../../Redux/Actions/commonAction";
import { cleanEntityData } from '../../Global/helper/commonUtil';
import { ProductClick } from '../../Global/helper/react-ga';

const styles = () => {

};
class TopProductComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultQuantity: 1,
            productPrice: "",
            showReviews: false,
            slideIndex: 0,
            isLoading: true,
            responsive: {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: { max: 4000, min: 1200 },
                    items: 7,
                    slidesToSlide: 7
                },
                desktop: {
                    breakpoint: { max: 1199, min: 768 },
                    items: 4,
                    slidesToSlide: 4
                },
                tablet: {
                    breakpoint: { max: 767, min: 464 },
                    items: 3,
                    slidesToSlide: 3
                },
                mobile: {
                    breakpoint: { max: 575, min: 320 },
                    items: 2,
                    slidesToSlide: 2
                }
            },
            topProductActiveIndex: []

        }
    }
    getleastPrice = (product) => {
        let maxNumber = 999999999999;
        let leastPrice = 0;
        let reduce = product?.child.map((x) => {
            maxNumber = Number(x.price) < maxNumber ? Number(x.price) : maxNumber
        });
        // console.log("calculating the least price", maxNumber)
        return maxNumber;
    }
    componentDidMount() {
        let data = [];
        _map(_get(this.props, 'topproduct'), (parent) => {
            data.push({ childBottleIndex: 0 })
        })

        this.setState({ topProductActiveIndex: data });
    }

    redirectToPDP = (item, bottleSize, childId) => {
        const bottleSizeDetail = _find(_get(item, 'child'), ['bottle_size', bottleSize])
        const payload = cleanEntityData({
            productId: _get(item, 'id'),
            name: _get(item, 'name'),
            variant: bottleSize,
            price: _get(bottleSizeDetail, 'price') ? Number(_get(bottleSizeDetail, 'price')) : undefined,
        });
        ProductClick(payload);

        this.props.dispatch(commonActionCreater(bottleSize, "SET_BOTTLE_SIZE"));
        this.props.dispatch(commonActionCreater(childId, "SET_BOTTLE_ID"));
        let ProductID = _get(item, "id", null);
        this.props.history.push(`/store/${ProductID}`);
    }

    setBottleSizeIndex = (parentBottleIndex, childBottleIndex, item) => {
        let data = this.state.topProductActiveIndex;
        data[parentBottleIndex].childBottleIndex = childBottleIndex;
        // this.redirectToPDP(item, _get(item, `child[${this.getActiveChildIndex(childBottleIndex)}].bottle_size`, ''))
        this.setState({ topProductActiveIndex: data })
    }

    getActiveChildIndex = (parentIndex) => {
        // console.log(_get(this.state, `topProductActiveIndex[${parentIndex}]`, 0), 'child infor')
        let childActiveIndex = _get(this.state, `topProductActiveIndex[${parentIndex}].childBottleIndex`, 0);
        return childActiveIndex;
    }
    handlebottleSizeClick = (data, parentIndex, item, childIndex) => {
    console.log("this is the item",data,parentIndex, item)
    this.setBottleSizeIndex(parentIndex, childIndex, item);
    this.redirectToPDP(item, _get(item, `child[${this.getActiveChildIndex(parentIndex)}].bottle_size`, ''), _get(item, `child[${this.getActiveChildIndex(parentIndex)}].id`, ''))
    }
    render() {

        let renderTopProductChild = ((data, parentIndex, item) => {

            return data && sortBy(data, [function (o) { return parseInt(o.bottle_size); }]).map((subItem, childIndex) => {

                return (<React.Fragment key={childIndex}>
                    <Button className={_get(this.state, `topProductActiveIndex[${parentIndex}].childBottleIndex`, 0) === childIndex ? 'active' : ''}
                        onClick={() => this.handlebottleSizeClick(data, parentIndex, item, childIndex)}
                        onMouseOver={() => this.setBottleSizeIndex(parentIndex, childIndex)}>
                        {subItem.bottle_size}
                    </Button>
                </React.Fragment>)


            })
        })

        let galleryItems = _map(_get(this.props, 'topproduct', []), (it, parentIndex) => {
            const filteredChildItem = _filter(_get(it, 'child', []), (i) => {
                if (_get(i, 'bottle_size') != '0') {
                    return i;
                }
            });
            const item = {
                ...it,
                child: filteredChildItem
            };
            // console.log('final items', finalItem);
            return (
                <React.Fragment key={parentIndex}>
                    <div className="storeItemsList">
                        <div onClick={() => this.redirectToPDP(item, _get(item, `child[${this.getActiveChildIndex(parentIndex)}].bottle_size`, ''), _get(item, `child[${this.getActiveChildIndex(parentIndex)}].id`, ''))}>
                            <div style={{ backgroundImage: `url(${item.child[0].image})`}} className="listProductImg"> 
                            {/* <div style={{ backgroundImage: `url(${wineImage})` }} className="listProductImg"> */}
                            </div>
                            <div className="productName">
                                {_get(item, 'name')}
                            </div>
                            <div className="displaySize">
                                {_get(item, `child[${this.getActiveChildIndex(parentIndex)}].bottle_size`, '')}ml
                            </div>
                            {_get(item, `child[${this.getActiveChildIndex(parentIndex)}].price`) > 0 &&
                                <div className="dispalyPrice mb-2">
                                    <i>from</i> ${this.getleastPrice(item)}
                                </div>
                            }
                            <div className="availableSize">
                                {/* {_get(item, 'child.length', '')} sizes available */}
                                {_isEmpty(_get(item, 'child')) ? '' : _get(item, 'child').length === 1 ? `${_get(item, 'child').length} size available` : `${_get(item, 'child').length} sizes available`}
                            </div>
                            <div className="hoverItems">
                                <Button onClick={() => this.redirectToPDP(item, _get(item, `child[${this.getActiveChildIndex(parentIndex)}].bottle_size`, ''), _get(item, `child[${this.getActiveChildIndex(parentIndex)}].id`, ''))} className="listPageAddcartbtn"> SHOP NOW </Button>
                                <div className="proSizes">
                                    {renderTopProductChild(_get(item, 'child', []), parentIndex, item)}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        })

        return (
            <React.Fragment>
                <div className="productListCarousel">
                    <Carousel responsive={this.state.responsive} showDots={false} itemClass="">
                        {galleryItems}
                    </Carousel>
                </div>
            </React.Fragment>

        )
    };
};
function mapStateToProps(state) {

    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(TopProductComponent));