
import React from 'react';
import { connect } from 'react-redux';

class RefundPolicy extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
      }

    render() {
        
        return (
            <React.Fragment>
              <div className="row my-5">
              <div className="col-md-12 mb-4"><h1>Cancellations, and Refunds Policy</h1></div>
                  <div className="col-md-12" style={{ textAlign: "justify", textJustify: "inter-word"}}>
                      <div>
                        <p>
                        This document is an electronic record in compliance with the Information Technology Act, 2000 and the Rules and Regulations framed there under, as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                        </p>
                      </div>
                      <div>
                        <p>
                        These Terms and Conditions (as may be amended from time to time, the "Agreement" or “Terms”) constitute a legal contract between You being a User of legal drinking age in your state of domicile (“You” or “User”), and DrinkBucket Private Limited, having its registered office at B-7, Saraswati Colony, Tonk Phatak, Jaipur, Rajasthan -302015 (“DrinksBucket”, “Company”, “We”, “Us”, or “Our”).
                        </p>
                      </div>
                      <div>
                        <p>
                        Drink Bucket®, DrinkBucket®, Drinks Bucket®, and DrinksBucket® are all registered trademark for the Company, and these names, individually and jointly are referred to as “DrinksBucket” in this document.
                        </p>
                      </div>
                      <div>
                        <p>
                        DrinksBucket owns and operates mobile applications namely “DrinksBucket”, “Buckets Delivery”, and the website www.DrinksBucket.com.  The mobile applications and the website will hereby be commonly referred by the term “DrinksBucket Application".
                        </p>
                      </div>
                      <div>
                        <p>
                        Please read the following terms and conditions carefully before browsing, downloading, registering, accessing, or using the DrinksBucket Application. By accessing, registering on, or using the DrinksBucket Application, or by using the Services provided by Us, as defined below, You agree to be bound by the terms and conditions set forth below including any additional guidelines and future modifications thereto. If, at any point of time, You do not agree to these terms and conditions or do not wish to be bound by any of these terms and conditions, You may not access or use the DrinksBucket Application, and terminate this Agreement, in terms hereof.
                        </p>
                      </div>
                      <div>
                        <p>
                        DrinksBucket Application provides a platform for liquor/alcoholic beverage manufacturers, retailers, distributors, and sellers (“Sellers”) to showcase liquor and alcoholic beverages ("Products") on the DrinksBucket Application, and enables You to search and pay for these Products through licensed alcohol beverage retailers, and other licensees with retail privileges (hereinafter referred to as the "Merchant"). We act as an intermediary and service provider, providing You with this platform enabling You to discover and purchase the Products from the Merchant. We provide You with payment solutions, payment support services, technology solutions and other related / ancillary services (hereinafter referred to as the “Services”). All transactions through the DrinksBucket Application are accepted, reviewed, and ultimately fulfilled by the Merchant. We do not warrant or take any responsibility or liability in respect of the services and products provided by the Merchant or the quality, authenticity, or the final delivery of Products.
                        </p>
                      </div>
                      <div style={{ marginTop: 20 }}>
                            <p>
                                <span style={{ fontWeight: "bold" }}>Cancellation </span>
                                <span>
                                As a general rule, You shall not be entitled to cancel Your order once You have received confirmation of the same from the Merchant. If You cancel Your order after it has been confirmed, DrinksBucket shall have a right to charge You a cancellation fee corresponding to the order value (inclusive of applicable taxes), with a right to either not to refund the order value or recover from Your subsequent order, the complete/ deficit cancellation fee, as applicable, to compensate Our Merchant and delivery agents. DrinksBucket shall also have the right to charge You cancellation fee for the orders cancelled by DrinksBucket for the reasons specified under clause c(iii) of this cancellation and refunds policy.
                                </span>
                            </p>
                      </div>
                      <div style={{ marginTop: 20 }}>
                            <p>
                                <span style={{ fontWeight: "bold" }}>Partial Order </span>
                                <span>
                                However, in the unlikely event of an item on Your order being unavailable, We will contact you on the phone number or email provided to Us at the time of placing the order and inform You of such unavailability. In such an event You will be entitled to modify or cancel the entire order and shall be entitled to a refund in accordance with Our refund policy.We reserve the sole right to modify/cancel Your order in the following circumstance:i. in the event of You having already opted for partial order acceptance during the placement of order;ii. in the event of the designated address falls outside the delivery zone offered by Us;iii. failure to contact You by phone or email at the time of confirming the order booking;iv. failure to deliver Your order due to lack of information, direction or authorization from You at the time of delivery; orv. unavailability of all the items ordered by You at the time of booking the order.
                                </span>
                            </p>
                      </div>
                      <div style={{ marginTop: 20 }}>
                            <p>
                                <span style={{ fontWeight: "bold" }}>Refunds </span>
                                <span>
                                However, in the unlikely event of an item on Your order being unavailable, We will contact you on the phone number or email provided to Us at the time of placing the order and inform You of such unavailability. In such an event You will be entitled to modify or cancel the entire order and shall be entitled to a refund in accordance with Our refund policy.We reserve the sole right to modify/cancel Your order in the following circumstance:i. in the event of You having already opted for partial order acceptance during the placement of order;ii. in the event of the designated address falls outside the delivery zone offered by Us;iii. failure to contact You by phone or email at the time of confirming the order booking;iv. failure to deliver Your order due to lack of information, direction or authorization from You at the time of delivery; orv. unavailability of all the items ordered by You at the time of booking the order.
                                </span>
                            </p>
                      </div>
                  </div>
              </div>       
            </React.Fragment>
          );
     }
}

export default RefundPolicy;